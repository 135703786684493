<template>
  <div class="mine-page">
    <!-- <div class="header-box">
      <img class="header" src="../../assets/images/jobs/banner.png" alt="">
    </div> -->

    

    <van-tabs v-model="activeName">
      <van-tab name="a">
        <template #title> 
          <van-icon name="user-o" />
          我的报名
        </template>
        <application></application>
      </van-tab>
      <van-tab name="b">
        <template #title> <van-icon name="star-o" /> 我的活动 </template>
        <eventlist></eventlist>
      </van-tab>
      <van-tab name="c">
        <template #title> <van-icon name="notes-o" />常见问答 </template>
        <faqlist></faqlist>
      </van-tab>
      <van-tab name="d">
        <template #title> <van-icon name="orders-o" />问卷列表 </template>
        <questionnairelist></questionnairelist>
      </van-tab>
      <van-tab name="e">
        <template #title> <van-icon name="friends-o" /> 家庭联系人 </template>
        <usercontact></usercontact>
      </van-tab>
    </van-tabs>

    <!-- <div class="mine-list">
      <ul class="my-jobs-list">
        <li class="mine-flex" @click="go('/mineApplication')">
          <p class="mine-flex">我的报名</p>
          <icon-svg class="icon-arrow" name="icon-arrow"></icon-svg>
        </li>
        <li class="mine-flex" @click="go('/mineEventList')">
          <p class="mine-flex">我的活动</p>
          <icon-svg class="icon-arrow" name="icon-arrow"></icon-svg>
        </li>
        <li class="mine-flex" @click="go('/faqList')">
          <p class="mine-flex">常见问答</p>
          <icon-svg class="icon-arrow" name="icon-arrow"></icon-svg>
        </li>
        <li class="mine-flex" @click="go('/questionnaireList')">
          <p class="mine-flex">问卷列表</p>
          <icon-svg class="icon-arrow" name="icon-arrow"></icon-svg>
        </li>
        <li class="mine-flex" @click="go('/userContact')">
          <p class="mine-flex">家庭联系人</p>
          <icon-svg class="icon-arrow" name="icon-arrow"></icon-svg>
        </li>
      </ul>
    </div> -->

  </div>
</template>

<script>
import cookies from "@/libs/util.cookies";

import application from './application/index.vue'
import eventlist from './eventList/index.vue'
import faqlist from './faqList/index.vue'
import questionnairelist from './questionnaireList/index.vue'
import usercontact from './userContact/index.vue'

export default {
  name: 'Mine',
  components: {
    application,
    eventlist,
    faqlist,
    questionnairelist,
    usercontact
  },
  watch: {
    "$route.query": {
      immediate: true,
      deep: true,
      handler(newValue) {
        if(newValue && newValue.active){
          this.activeName = newValue.active
        }
      },
    },
  },
  data() {
    return {
      hasResume: false,
      user_id: cookies.get('uid') || '',
      activeName:'a'
    }
  },
  created() {

  },
  mounted() {
    this.resumeDetail()
  },
  methods: {
    go(path) {
      this.$router.push({ path: path })
    },
    resumeDetail() {

    },

  }
}
</script>

<style lang='scss' scoped>
.header-box {
  position: relative;

  .text {
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
    color: #fff;
    padding-left: 38px;
    padding-top: 46px;
    overflow-x: hidden;

    p {
      margin: 0;
      padding: 0;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 28px;
    }

    p.title {
      font-size: 35px;
      font-family: DINCondensed-Bold, DINCondensed;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 35px;
    }
  }
}

.header {
  width: 100%;
  display: block;
}

.mine-list {
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
  background: #fff;
  position: relative;
  z-index: 9;
  top: 20px;
}

.mine-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my-jobs-list {
  font-size: 16px;
  color: #333;

  li {
    border-bottom: 1px solid #F1F4F6;
  }

  p {
    margin: 0;
    padding: 14px 0;
  }

  .icon-item-my {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .icon-item {
    margin-left: 5px;
    width: 21px;
    height: 21px;
    margin-right: 12px;
  }

  .icon-arrow {
    width: 16px;
    height: 16px;
  }
}

.resume-content {
  position: relative;
  padding: 22px 20px;
  background: linear-gradient(90deg, #F5F8FF 0%, #ECF2FF 100%);
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  line-height: 25px;
  margin-bottom: 11px;

  .icon-resume {
    width: 25px;
    height: 23px;
    margin-right: 8px;
  }

  p {
    margin: 0;
  }

  a {
    width: 80px;
    height: 30px;
    background: linear-gradient(90deg, #e95944 0%, #e95944 100%);
    border-radius: 26px;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 30px;
    position: relative;
    z-index: 99;
  }
}

.resume-content::after {
  display: block;
  content: '';
  width: 135px;
  height: 100%;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  right: 25px;
}

::v-deep{
  .van-tabs--line .van-tabs__wrap{
    height:auto;
  }

  .van-tabs__nav{
    flex-wrap: wrap;
  }

  .van-tab{
    min-width:25%;
    max-width:25%;
    padding: 10px 0;
  }

  .van-tab .van-icon{
    font-size: 40px;
    display:block;
  }

  .van-tab__text--ellipsis{
    -webkit-line-clamp: 2;
  }

  .van-tabs__line{
    display:none;
  }
}


@media (min-width: 992px) {

  ::v-deep{
    .van-tabs{
      max-width: 900px;
      margin: 0 auto;
    }

    .van-tabs__nav--line{
      padding-bottom: 10px;
    }

    .van-tab{
      min-width:20%;
      max-width:20%;
      padding: 10px 0;
      font-size:16px;
      line-height: 2;
    }

    .van-tab .van-icon{
      font-size:40px;
    }

  }
  .header-box {
    position: relative;

    .text {
      position: absolute;
      top: 0;
      left: 0;
      text-align: left;
      color: #fff;
      padding-left: 38px;
      padding-top: 46px;
      overflow-x: hidden;

      p {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 28px;
      }

      p.title {
        font-size: 35px;
        font-family: DINCondensed-Bold, DINCondensed;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 35px;
      }
    }
  }

  .header {
    width: 100%;
    display: block;
  }

  .mine-list {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 20px 20px 0px 0px;
    background: #fff;
    position: relative;
    z-index: 9;
    top: 20px;
  }

  .mine-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .my-jobs-list {
    font-size: 16px;
    color: #333;

    li {
      border-bottom: 1px solid #F1F4F6;
    }

    p {
      margin: 0;
      padding: 14px 0;
    }

    .icon-item-my {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }

    .icon-item {
      margin-left: 5px;
      width: 21px;
      height: 21px;
      margin-right: 12px;
    }

    .icon-arrow {
      width: 16px;
      height: 16px;
    }
  }

  .resume-content {
    position: relative;
    padding: 22px 20px;
    background: linear-gradient(90deg, #F5F8FF 0%, #ECF2FF 100%);
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 25px;
    margin-bottom: 11px;

    .icon-resume {
      width: 25px;
      height: 23px;
      margin-right: 8px;
    }

    p {
      margin: 0;
    }

    a {
      width: 80px;
      height: 30px;
      background: linear-gradient(90deg, #e95944 0%, #e95944 100%);
      border-radius: 26px;
      color: #FFFFFF;
      font-size: 12px;
      line-height: 30px;
      position: relative;
      z-index: 99;
    }
  }

  .resume-content::after {
    display: block;
    content: '';
    width: 135px;
    height: 100%;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    right: 25px;
  }
}
</style>

<style lang="scss">
  @media (min-width: 992px) {

    .van-dialog{
      width:320px;
      font-size:14px;
      border-radius:10px;
      box-shadow: 10px 10px 20px #efefef;
    }
    .van-dialog__message{
      font-size:16px;
      line-height: 1.5;
      padding:20px 20px;
    }

    .van-dialog__content--isolated{
      min-height:150px;
    }
  }
</style>